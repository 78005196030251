.features {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15vh 8.5vw;
    gap: 7.5vh;

    .features-title {
        font-weight: 600;
        font-size: 3.35rem;
        line-height: 1.4;
        color: #fff;
        text-align: center;
        margin: 0;
    }

    .features-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .features-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 3.5vh;

            img {
                width: 22.5vw;
                height: auto;
                border-radius: 30px;
                border: 1px solid #eaeaea;
            }

            h3 {
                font-weight: 600;
                font-size: 2.2rem;
                color: #fff;
            }
        }
    }
}

@media (max-width: 1600px) {
    .features {
        .features-title {
            font-size: 2.45rem;
        }

        .features-container {
            .features-card {
                h3 {
                    font-size: 2rem;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .features {
        padding: 12.5vh 8.5vw;
        gap: 3.5vh;

        .features-title {
            font-size: 1.95rem;
        }

        .features-container {
            .features-card {
                gap: 2vh;

                img {
                    width: 22.5vw;
                }

                h3 {
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .features {
        padding: 15vh 5vw;
        gap: 7.5vh;

        .features-title {
            font-size: 1.5rem;
        }

        .features-container {
            flex-direction: column;
            gap: 6.5vh;

            .features-card {
                gap: 2.75vh;

                img {
                    width: 70vw;
                }

                h3 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
