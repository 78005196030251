.hero {
    width: 100vw;
    padding: 17.5vh 7.5vw 10vh 7.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../../../assets/img/dentist/home/img_hero_bg.webp");
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;

    .title {
        font-size: 4.8rem;
        line-height: 1.4;
        color: #fff;
        font-weight: 600;
        text-align: center;
        margin-bottom: 5px;
    }

    .subtitle {
        font-size: 4.8rem;
        line-height: 1.4;
        color: #fff;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
    }

    .btn-book-demo,
    .btn-try-for-free {
        background-color: #f1fcff;
        color: #000000;
        border-radius: 30px;
        font-size: 1.25rem;
        padding: 10px 40px;
        border: none;
        margin-top: 4.5vh;
        margin-bottom: 5vh;
        transition: all 0.3s;

        &:hover {
            background-color: #d3f4fb;
        }

        &:active {
            background-color: #59d7ee;
        }
    }
}

@media (max-width: 1600px) {
    .hero {
        .title,
        .subtitle {
            font-size: 3.8rem;
        }
    }
}

@media (max-width: 1024px) {
    .hero {
        padding: 15vh 7.5vw 10vh 7.5vw;
        background-position: center 90%;

        .subtitle {
            white-space: normal;
        }

        .btn-book-demo,
        .btn-try-for-free {
            margin-top: 2.5vh;
            margin-bottom: 2.5vh;
        }
    }
}

@media (max-width: 820px) {
    .hero {
        padding-top: 17.5vh;

        .title,
        .subtitle {
            font-size: 3.2rem;
        }

        .btn-book-demo,
        .btn-try-for-free {
            margin-bottom: 5vh;
        }
    }
}

@media (max-width: 480px) {
    .hero {
        padding: 20vh 5vw 10vh 5vw;
        background-position: 60% bottom;
        background-size: cover;

        .title,
        .subtitle {
            font-size: 1.8rem;
        }

        .subtitle {
            white-space: normal;
        }

        .btn-book-demo,
        .btn-try-for-free {
            font-size: 0.8rem;
            padding: 10px 25px;
        }
    }
}
