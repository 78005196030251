.home-page {
    width: 100vw;
    background-image: url("../../assets/img/dentist/home/img_home_bg.webp");
    background-position: top left;
}

@media (max-width: 1024px) {
}

@media (max-width: 480px) {
}
