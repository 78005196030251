.cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 20vh 0;
    gap: 3.5vh;

    .cta-title {
        width: 62.5vw;
        font-weight: 600;
        font-size: 4.85rem;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        margin-bottom: 0;
    }

    .btn-start-now {
        background-color: #f1fcff;
        color: #000000;
        border-radius: 30px;
        font-size: 1.325rem;
        font-weight: 600;
        padding: 12.5px 50px;
        border: none;
        transition: all 0.3s;

        &:hover {
            background-color: #d3f4fb;
        }

        &:active {
            background-color: #59d7ee;
        }
    }
}

@media (max-width: 1600px) {
    .cta {
        .cta-title {
            font-size: 4.25rem;
        }
    }
}

@media (max-width: 1024px) {
    .cta {
        padding: 15vh 0;

        .cta-title {
            font-size: 2.5rem;
        }
    }
}

@media (max-width: 820px) {
    .cta {
        .cta-title {
            font-size: 2rem;
        }
    }
}

@media (max-width: 480px) {
    .cta {
        gap: 5vh;

        .cta-title {
            width: 80vw;
            font-size: 1.5rem;
        }

        .btn-start-now {
            padding: 10px 60px;
            font-size: 1.3rem;
        }
    }
}
