.whole-step-container {
    box-sizing: border-box;
    width: 100vw;
    padding: 10vh 8.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10vh;

    .title-container {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .text-title {
            font-size: 3.35rem;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin-bottom: 0;
        }
    }

    .detailed-steps-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 5vw;

        .step-box {
            display: flex;
            flex-direction: column;
            align-items: center;

            .step-image {
                width: 6.5vw;
                height: auto;
                margin-bottom: 50px;
            }

            .text-step-index {
                font-size: 1.5rem;
                color: #59d7ee;
                font-weight: 600;
                text-align: center;
                margin-bottom: 12.5px; // Spacing between text elements
            }
            .text-step-name {
                font-size: 2.2rem;
                color: #ffffff;
                font-weight: 600;
                text-align: center;
                margin-bottom: 12.5px; // Spacing between text elements
            }
            .text-step-description {
                width: 20vw;
                font-size: 1.3rem;
                color: #eaeaea;
                text-align: center;
                margin-bottom: 12.5px; // Spacing between text elements
            }
        }
    }
}

@media (max-width: 1600px) {
    .whole-step-container {
        .title-container {
            .text-title {
                font-size: 2.45rem;
            }
        }

        .detailed-steps-container {
            .step-box {
                .step-image {
                    margin-bottom: 45px;
                }

                .text-step-index {
                    font-size: 1.3rem;
                }
                .text-step-name {
                    font-size: 2rem;
                }
                .text-step-description {
                    width: 22.5vw;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .whole-step-container {
        padding: 7.5vh 8.5vw;
        gap: 5vh;

        .title-container {
            .text-title {
                font-size: 36px;
            }
        }

        .detailed-steps-container {
            flex-direction: column;

            .step-box {
                .step-image {
                    width: 8vw;
                    margin-bottom: 25px;
                }
                .text-step-name {
                    font-size: 1.45rem;
                }
                .text-step-description {
                    width: 50vw;
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .whole-step-container {
        .title-container {
            .text-title {
                font-size: 28px;
                line-height: 1.5;
            }
        }

        .detailed-steps-container {
            gap: 8.5vh;

            .step-box {
                .step-image {
                    width: 18vw;
                    margin-bottom: 3vh;
                }
                .text-step-index {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                }
                .text-step-name {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                }
                .text-step-description {
                    width: 75vw;
                }
            }
        }
    }
}
